$(document).ready(function () {
  // video player
  $('.video-poster').each(function () {
    const videoPlayer = $(this)
    const playButton = videoPlayer.find('.play-button')
    const video = videoPlayer.find('iframe')
    const videoID = $(video).attr('video-id')
    const stopVideo = videoPlayer.find('.stop-video')
    playButton.on('click', function () {
      video.attr('src', 'https://www.youtube.com/embed/' + videoID + '?autoplay=1&playsinline=1&mute=1&loop=1&playlist=' + videoID)
      video.show()
      videoPlayer.addClass('video-poster--active')
    })

    // Close video (used in "hero-flexible-image")
    stopVideo.on('click', function() {
      video.attr('src', '')
      video.hide()
      videoPlayer.removeClass('video-poster--active')
    })
  })
})
